import Clipboard from 'clipboard/dist/clipboard.min.js'

export default function (app) {
    app.directive('clipboard', {
        mounted(el, binding) {
            if (binding.arg === 'success') {
                el._vClipboard_success = binding.value
            } else if (binding.arg === 'error') {
                el._vClipboard_error = binding.value
            } else {
                let clipboard = new Clipboard(el, {
                    text: function () {
                        return binding.value;
                    },
                    action: function () {
                        return binding.arg === 'cut' ? 'cut' : 'copy';
                    }
                })
                clipboard.on('success', function (e) {
                    let callback = el._vClipboard_success;
                    callback && callback(e);
                });
                clipboard.on('error', function (e) {
                    let callback = el._vClipboard_error;
                    callback && callback(e);
                });
                el._vClipboard = clipboard;
            }
        },
        updated(el, binding) {
            if (binding.arg === 'success') {
                el._vClipboard_success = binding.value
            } else if (binding.arg === 'error') {
                el._vClipboard_error = binding.value
            } else {
                el._vClipboard.text = function () { return binding.value }
                el._vClipboard.action = function () { return binding.arg === 'cut' ? 'cut' : 'copy' }
            }
        },
        unmounted(el, binding) {
            if (binding.arg === 'success') {
                delete el._vClipboard_success;
            } else if (binding.arg === 'error') {
                delete el._vClipboard_error;
            } else {
                el._vClipboard.destroy();
                delete el._vClipboard;
            }
        }
    })
}