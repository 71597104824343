import { createApp, reactive } from "vue";
import { createPinia, PiniaVuePlugin } from "pinia";
import { auth } from "@/firebase";
import { onAuthStateChanged } from "firebase/auth";

import App from "./App.vue";
import router from "./router/index";
import rollbar from "./rollbar";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import VClipboard from "./directives/VClipboard.js";

import "./vue-awesome-icons.js";
import "./assets/setupStyle.css";
import "./assets/style.css";

const pinia = createPinia();

// app.config.errorHandler = (err, vm) => {
//   vm.$rollbar.error(err);
//   throw err; // rethrow
// };

let app;

onAuthStateChanged(auth, (user) => {
  rollbar.configure({
    payload: user ? { person: { id: user.uid } } : {},
  });

  if (!app) {
    app = createApp(App);
    app.component("VIcon", FontAwesomeIcon);
    app.use(PiniaVuePlugin);
    app.use(VClipboard);
    app.use(router);
    app.use(pinia);

    app.config.globalProperties.$toastEvents = reactive([]);
    app.config.globalProperties.$rollbar = rollbar;

    app.mount("#app")
  }
});
