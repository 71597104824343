<template>
  <section class="global-toast-group">
    <div class="global-toast-group__array">
      <transition-group name="fade" tag="span">
        <div v-for="toast in events" :key="toast.id" :class="toast.type">
          <VToastGroupItem
            :type="toast.type"
            :title="toast.title"
            :content="toast.content"
            :timestamp="toast.id"
          />
        </div>
      </transition-group>
    </div>
  </section>
</template>

<script>
import VToastGroupItem from "./VToastGroupItem.vue";

export default {
  components: {
    VToastGroupItem,
  },
  data() {
    return {
      events: this.$toastEvents,
    };
  },
  watch: {
    events: {
      handler: function () {
        this.events = this.$toastEvents;
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.global-toast-group {
  position: absolute;
  z-index: 99999;
}

.global-toast-group__array {
  display: flex;
  position: fixed;
  color: var(--grey-dark);
  border: 1px;
  border-radius: 8px;
  top: 112px;
  right: 20px;
}

.positive {
  background-color: #eaf4e8;
  border-radius: 8px;
}

.copy {
  border-radius: 8px;
  background-color: #e0eefb;
}

.negative {
  border-radius: 8px;
  background-color: rgb(251, 224, 224);
}
</style>
