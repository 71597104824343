<template>
  <div id="app">
    <router-view />
    <VToastGroup />
  </div>
</template>

<script>
import bus from "@/bus";
import VToastGroup from "./components/GlobalComponents/VToastGroup.vue";

export default {
  name: "App",
  components: {
    VToastGroup,
  },
  data() {
    return {
      hideOverflow: false,
    };
  },
  watch: {
    // whenever active changes, this function will run
    hideOverflow: function () {
      document.body.style.overflow = this.hideOverflow ? "hidden" : "";
    },
  },
  created() {
    bus.$on("bodyOverflow", (state) => (this.hideOverflow = state));
  },
};
</script>

<style>
html,
body,
#app {
  height: 100%;
  min-height: 100%;
  font-family: "Poppins", sans-serif;
}

body {
  background-color: #f7f7f7;
  overflow-y: scroll;
}

body.modal-open {
  overflow: hidden;
}

h5,
h6,
p,
a,
ul,
li,
input,
textarea {
  font-weight: 400;
}

h3 {
  font-weight: 500;
}

h4 {
  font-weight: 200;
}

.regularParagraph {
  font-weight: 800;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}

.dateCalendarBelow {
  z-index: 101;
  right: 0px;
}

.dateCalendarAbove {
  z-index: 101;
  left: 0px;
  top: -282px;
}

@media (max-width: 760px) {
  div#mceu_20-body {
    display: none !important;
  }
}

.fadeInModal-enter-active,
.fadeInModal-leave-active {
  transition: all 0.2s ease;
}

.fadeInModal-enter-from {
  transform: translateY(-100px);
  opacity: 0;
}
.fadeInModal-leave-to {
  transform: translateY(100px);
  opacity: 0;
}

/*
  Animation test
 */

/* .post-loading-animation {
  -webkit-animation: fadein 2s;
  -moz-animation: fadein 2s;
  -ms-animation: fadein 2s;
  -o-animation: fadein 2s;
  animation: fadein 2s;
}

.exit-animation {
  -webkit-animation: fadeOut 0.5s;
  -moz-animation: fadeOut 0.5s;
  -ms-animation: fadeOut 0.5s;
  -o-animation: fadeOut 0.5s;
  animation: fadeOut 0.5s;
} */

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Internet Explorer */
@-ms-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>
