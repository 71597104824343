import { createRouter, createWebHistory } from 'vue-router'
import { auth } from "@/firebase";

let router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      name: "unauthorized",
      path: "/unauthorized",
      component: () => import("@/views/Unauthorized.vue"),
    },
    {
      name: "hotels",
      path: "/hotels",
      component: () => import("@/views/Hotels.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      name: "login",
      path: "/login",
      component: () => import("@/views/LoginView.vue"),
    },
    {
      name: "user-management",
      path: "/usermgmt",
      component: () => import("@/views/UserManagementView.vue"),
    },
    {
      // TODO: remove temporary redirect
      path: "/edit",
      redirect: "/login",
    },
    {
      path: "/",
      redirect: "/login",
      component: () => import("@/views/Layout.vue"),
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          name: "news",
          path: "/:hotel/news",
          component: () => import("@/views/NewsView.vue"),
          children: [
            {
              name: "news-article",
              path: ":name/:id",
              component: () =>
                import("@/components/NewsPage/NewsArticlesItem.vue"),
              props: route => ({
                articleName: route.params.name,
                articleID: route.params.id
              })
            },
          ]
        },
        {
          name: "settings",
          path: "/:hotel/settings",
          redirect: to => ({ name: 'settings-customization', params: to.params }),
          component: () => import("@/views/SettingsView.vue"),
          children: [
            {
              name: "settings-customization",
              path: "/:hotel/settings/customization",
              component: () =>
                import("@/components/SettingsPage/CustomizationTab.vue"),
            },
            {
              name: "settings-account",
              path: "/:hotel/settings/account",
              component: () =>
                import("@/components/SettingsPage/AccountTab.vue"),
            },
            {
              name: "settings-help",
              path: "/:hotel/settings/help",
              component: () => import("@/components/SettingsPage/HelpTab.vue"),
            },
            {
              name: "manuals-help",
              path: "/:hotel/settings/manuals",
              component: () => import("@/components/SettingsPage/ManualsTab.vue"),
            },
          ],
        },
        {
          name: "rooms",
          path: "/:hotel/rooms",
          redirect: to => ({ name: 'room-list', params: to.params }),
          component: () => import("@/views/RoomView.vue"),
          children: [
            {
              name: "room-list",
              path: "/:hotel/rooms/list",
              component: () => import("@/components/RoomsPage/RoomsList.vue"),
            },
            {
              name: "room-messages",
              path: "/:hotel/rooms/messages",
              component: () =>
                import("@/components/MessagesPage/MessageScheduler.vue"),
            },
          ],
        },
        {
          name: "guestbook",
          path: "/:hotel/guestbook",
          component: () =>
            import("@/components/EditGuestbookPage/GuestbookPage.vue"),
        },
        {
          name: "statistics",
          path: "/:hotel/statistics",
          redirect: to => ({ name: 'platform-statistics', params: to.params }),
          component: () => import("@/views/StatisticsView.vue"),
          children: [
            {
              name: "platform-statistics",
              path: "/:hotel/statistics/platform",
              component: () =>
                import(
                  "@/components/StatisticsPage/StatisticsGuests/StatisticsGuests.vue"
                ),
            },
            {
              name: "voucher-statistics",
              path: "/:hotel/statistics/voucher",
              component: () =>
                import(
                  "@/components/StatisticsPage/StatisticsVouchers/StatisticsVouchers.vue"
                ),
            },
            {
              name: "products-statistics",
              path: "/:hotel/statistics/products",
              component: () =>
                import(
                  "@/components/StatisticsPage/StatisticsProducts/StatisticsProducts.vue"
                ),
            },
            {
              name: "reservation-statistics",
              path: "/:hotel/statistics/reservations",
              component: () =>
                import(
                  "@/components/StatisticsPage/StatisticsReservations/StatisticsReservations.vue"
                ),
            },
            {
              name: "service-statistics",
              path: "/:hotel/statistics/services",
              component: () =>
                import(
                  "@/components/StatisticsPage/StatisticsServices/StatisticsServices.vue"
                ),
            },
          ],
        },
        {
          name: "automated-messages",
          path: "/:hotel/automated-messages",
          redirect: to => ({ name: 'automated-messages-pre-checkin', params: to.params }),
          component: () => import("@/views/AutomatedMessagesView.vue"),
          children: [
            {
              name: "automated-messages-pre-checkin",
              path: "/:hotel/automated-messages/pre-checkin",
              component: () =>
                import(
                  "@/components/AutomatedMessagesPage/PrecheckinSettings.vue"
                ),
            },
            {
              name: "automated-messages-checkin",
              path: "/:hotel/automated-messages/checkin",
              component: () =>
                import(
                  "@/components/AutomatedMessagesPage/CheckinSettings.vue"
                ),
            },
            {
              name: "automated-messages-checkout",
              path: "/:hotel/automated-messages/checkout",
              component: () =>
                import(
                  "@/components/AutomatedMessagesPage/CheckoutSettings.vue"
                ),
            },
            {
              name: "automated-messages-after-stay",
              path: "/:hotel/automated-messages/after-stay",
              component: () =>
                import(
                  "@/components/AutomatedMessagesPage/AfterStaySettings.vue"
                ),
            },
          ],
        },
        {
          name: "media",
          path: "/:hotel/media",
          redirect: to => ({ name: 'media-images', params: to.params }),
          component: () => import("@/views/MediaView.vue"),
          children: [
            {
              name: "media-images",
              path: "/:hotel/media/images",
              component: () =>
                import("../components/MediaPage/MediaImages.vue"),
            },
            {
              name: "media-pdf",
              path: "/:hotel/media/pdf",
              component: () => import("../components/MediaPage/MediaPdfs.vue"),
            },
          ],
        },
        {
          name: "pages",
          path: "/:hotel/pages",
          redirect: to => ({ name: 'list-pages', params: to.params }),
          component: () => import("@/views/PagesView.vue"),
          children: [
            {
              name: "list-pages",
              path: "/:hotel/pages/list",
              component: () =>
                import("@/components/PagesPage/PagesOverview.vue"),
            },
            {
              name: "new-page",
              path: "/:hotel/new-page",
              component: () =>
                import("@/components/PagesPage/PagesCreateNew.vue"),
            },
          ],
        },
        {
          name: "qr-code",
          path: "/:hotel/qr-code",
          component: () => import("@/views/QRView.vue"),
        },
        {
          name: "welcome-event-manager",
          path: "welcome-event-manager",
          beforeEnter() {
            window.location.href = "https://www.wellcome.biz";
          },
        },
      ],
    },
  ],
});

// TODO: could be useful for tailwind refactor.
if (import.meta.env.VITE_DEPLOYMENT_TYPE !== "production") {
  router.addRoute(
    {
      path: "/global-css-test",
      name: "Global CSS Test",
      component: () => import("@/views/GlobalStylingTestView.vue"),
    },
  );
}

router.addRoute(
  {
    name: "not-found",
    path: "/:pathMatch(.*)*",
    component: () => import("@/views/NotFound.vue"),
  },
);

router.beforeEach((to, from, next) => {
  let currentUser = auth.currentUser;
  let requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth && !currentUser) next("login");
  // else if (!requiresAuth && currentUser) next("edit");
  else next();
});

export default router;
